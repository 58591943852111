import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues, initialize, destroy } from 'redux-form';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import ModalWindow from '../../../ModalWindow';
import { PrimaryButton, CancelButton } from '../../../../components/UIKit';
import { closeModalWindow } from '../../../ModalWindow/slice';
import i18n from '../../../../i18n';
import { Expander } from '../../../../components';
import {
  editTariffId as editTariffFormID,
  tariffTypes,
  PROVIDERS_LIST_ID
} from '../../constants';

import DataList from '../../../DataList';
import { PROVIDERS_URL } from '../../../../api/apiUrls';
import { SingleTariff } from './SingleTariff';
import { DoubleTariff } from './DoubleTariff';
import { DynamicTariff } from './DynamicTariff';
import { HelionExportTariffSection } from './HelionExportTariffSection';
import { ControlSection } from './ControlSection';

const formSelector = createSelector(
  getFormValues(editTariffFormID),
  (formValues) => formValues
);

const EditTariffWindow = (props) => {
  const { reset, handleSubmit, unit, myRoleType, user, initialValues } = props;
  const dispatch = useDispatch();
  const formValues = useSelector(formSelector) || {};
  const modal = useSelector((state) => state.modals[editTariffFormID]);
  const { tariffType } = formValues;
  const handleOnClose = () => dispatch(closeModalWindow({ modalID: editTariffFormID }));

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  useEffect(() => {
    if (initialValues || modal?.data?.formData) {
      dispatch(initialize(editTariffFormID, { ...initialValues, ...(modal?.data?.formData || {}) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, modal?.data?.formData]);

  useEffect(() => () => dispatch(destroy(editTariffFormID)), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DataList listID={PROVIDERS_LIST_ID} listURL={PROVIDERS_URL} headers={{ 'api-version': 3 }} />
      <ModalWindow modalID={editTariffFormID}>
        <div className="modal-header">
          <h5 className="modal-title" style={{ color: '#000' }}>{i18n.t('tariffSettings')}</h5>
        </div>
        <div className="modal-body">
          <form
            id={editTariffFormID}
            onSubmit={handleSubmit}
            className="m-login__form m-form pop-up-form edit-tariff-form"
          >
            <HelionExportTariffSection formValues={formValues} myRoleType={myRoleType} unit={unit} user={user} />
            <ControlSection />
            <Expander expanded={tariffType === tariffTypes.single}>
              <SingleTariff unit={unit} skipValidation={tariffType !== tariffTypes.single} />
            </Expander>
            <Expander expanded={tariffType === tariffTypes.double}>
              <DoubleTariff
                formValues={formValues}
                unit={unit}
                skipValidation={tariffType !== tariffTypes.double}
              />
            </Expander>
            <Expander expanded={tariffType === tariffTypes.dynamic}>
              <DynamicTariff
                formValues={formValues}
                initialValues={initialValues}
                user={user}
                skipValidation={tariffType !== tariffTypes.dynamic}
              />
            </Expander>
          </form>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={handleOnClose} />
          <PrimaryButton form={editTariffFormID} isSubmit type="save" />
        </div>
      </ModalWindow>
    </>
  );
};

EditTariffWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  unit: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired
};

const form = reduxForm({
  form: editTariffFormID
})(EditTariffWindow);

export default form;
